<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">포인트 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"> <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터 </v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                        <v-row align="center">
                            <v-col cols="12" sm="3" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" item-text="text" item-value="value" hide-details label="검색조건" persistent-placeholder></v-select>
                            </v-col>
                            <v-col cols="12" sm="3" class="py-0">
                                <v-text-field
                                    v-model="filter.searchValue"
                                    hide-details
                                    type="text"
                                    label="검색어"
                                    :disabled="!filter.searchKey"
                                    persistent-placeholder
                                    @keydown.enter="
                                        page = 1;
                                        init();
                                    "
                                />
                            </v-col>
                            <v-col cols="12" sm="3" class="py-0">
                                <v-date-field
                                    v-model="filter.createdAt[0]"
                                    label="시작일자"
                                    type="date"
                                    persistent-placeholder
                                    hide-details
                                    clearable
                                    @input="
                                        page = 1;
                                        init();
                                    "
                                />
                            </v-col>
                            <v-col cols="12" sm="3" class="py-0">
                                <v-date-field
                                    v-model="filter.createdAt[1]"
                                    label="종료일자"
                                    type="date"
                                    persistent-placeholder
                                    hide-details
                                    clearable
                                    @input="
                                        page = 1;
                                        init();
                                    "
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="init"> <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색 </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-row align="center">
                <v-col cols="auto" class="pt-2 pb-0">
                    <v-card outlined v-bind="{ loading }">
                        <v-btn color="white" class="caption" v-bind="{ loading }">
                            <v-row>
                                <v-col>
                                    <v-layout class="my-2" align-baseline>
                                        <span>검색 결과: </span>
                                        <b>{{ (summary?.totalCount || 0)?.format() }}</b>건
                                    </v-layout>
                                </v-col>
                                <v-col>
                                    <v-layout class="my-2" align-baseline>
                                        <span>포인트 지급 금액: </span>
                                        <b>{{ (totalPoint || 0)?.format() }}</b>원
                                    </v-layout>
                                </v-col>
                            </v-row>
                        </v-btn>
                    </v-card>
                </v-col>
                <v-col cols="auto" class="pt-2 pb-0">
                <!-- <v-btn color="white" class="green--text px-2 ml-1" v-bind="{ loading }" @click="manualGive">수동 지급</v-btn> -->
            </v-col>
            </v-row>

            <v-data-table :headers="headers" :items="points" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-3" :items-per-page="-1">
                <template #[`item.no`]="{ item }">
                    {{ (page - 1) * limit + (+points.indexOf(item) + 1) }}
                </template>
                <template #[`item.amount`]="{ item }"> {{ item.amount.format() }}원 </template>
                <template #[`item.remain`]="{ item }"> {{ item.remain.format() }}원 </template>
                <template #[`item.createdAt`]="{ item }">
                    {{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mx-4" @input="search()"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { mdiMicrosoftExcel } from "@mdi/js";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

const headers = [
    { text: "no", align: "center", width: 50, value: "no" },
    { text: "이름", align: "center", width: 130, value: "user.username" },
    { text: "연락처", align: "center", width: 130, value: "user.phone" },
    { text: "포인트 금액", align: "center", width: 130, value: "amount" },
    { text: "남은 금액", align: "center", width: 130, value: "remain" },
    { text: "비고", width: 200, align: "center", value: "remark" },
    { text: "일자", align: "center", width: 130, value: "createdAt" },
];

export default {
    components: {
        VDateField,
    },
    data() {
        return {
            mdiMicrosoftExcel,
            showSearch: true,

            filter: {
                createdAt: [],
                searchKey: null,
                searchValue: null,
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 20,

            tabIndex: 0,

            headers,
            points: [],
            summary: { totalCount: 0 },
            totalPoint: 0,

            levels: [],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "이름", value: "user.username" },
                { text: "연락처", value: "user.phone" },
            ],
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
            await this.getTotalPoint();
        },
        async search() {
            try {
                var { summary, points } = await api.console.users.points.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.points = points;
                this.summary = summary;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },
        async getTotalPoint() {
            try {
                let { totalPoint } = await api.console.users.points.getsTotalPoint({
                    params: this.filter
                });

                this.totalPoint = totalPoint;
            } catch(error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        // async manualGive() {
        //     try {
        //         await api.console.users.points.post({
        //             phones: ["01036732158","01089697289","01031308461","01094966205","01087365412","01047635970","01075044058","01098445815","01039086503","01088516970","01056904722","01036932125","01053971094","01037402585"]
        //         })

        //     } catch(error) {
        //         console.error(error);
        //         alert(error.response ? error.response.data.message : error.message);
        //     }
        // },

        async excel() {
            try {
                var { users } = await api.console.users.gets({
                    headers: {
                        limit: 0,
                    },
                    params: this.filter,
                });

                var rows = users.map((user) => ({
                    가입일자: user?.createdAt?.toDateTime?.(),
                    유형: user?.typeText,
                    아이디: user?.username,
                    "기업명/사용자명": user?.name,
                    국가: user?.nation,
                    연락처: user?.phone,
                    이메일: user?.email,
                    생년월일: user?.bornAt?.toDateTime?.(),
                    성별: user?.sex,
                    직업: user?.occupation,
                    직장: user?.companyName,
                    사업자등록번호: user?.businessRegisitrationNumber,
                    기업설립일: user?.foundAt?.toDateTime?.(),
                    대표자명: user?.ceoName,
                    홈페이지: user?.homepage,
                    주의회원여부: user?.isWarning ? "주의" : "-",
                }));

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "회원목록.xlsx");
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        },
    },
};
</script>
